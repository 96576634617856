import { useSearchState } from '@hotelplan/fdr.lib.search.with-state';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import { ESearchType } from 'fdr/components/candidate/fdr-search-control/destination/fdr-destination-field.types';
import { normalizeFilters } from 'fdr/components/candidate/fdr-search-filters-controls/use-fdr-filter-render.utils';
import { fdrMapFilters } from 'fdr/components/candidate/fdr-search/mappers/fdr-filter.mapper';
import { IFdrFiltersFormState } from 'fdr/components/candidate/fdr-search/types/filters.types';
import { IFdrSRLState } from 'fdr/components/candidate/fdr-search/types/search-form.types';
import { T_Custom_FdrTravelTypeUnit } from 'fdr/components/candidate/fdr-search/types/travel-type.types';
import {
  FdrFlightSearchControlsQuery,
  useFdrFlightSearchControlsLazyQuery,
} from 'fdr/schemas/query/flight-search/form/fdr-flight-search-controls.generated';
import {
  FdrGeoSearchControlsQuery,
  useFdrGeoSearchControlsLazyQuery,
} from 'fdr/schemas/query/geo/search-controls/fdr-geo-search-controls.generated';
import {
  FdrPdpSearchControlsQuery,
  useFdrPdpSearchControlsLazyQuery,
} from 'fdr/schemas/query/pdp/search-controls/fdr-pdp-search-controls.generated';
import {
  FdrSearchControlsQuery,
  useFdrSearchControlsLazyQuery,
} from 'fdr/schemas/query/search/search-controls/fdr-search-controls.generated';
import {
  FdrThemeSearchControlsQuery,
  useFdrThemeSearchControlsLazyQuery,
} from 'fdr/schemas/query/theme/search-controls/fdr-theme-search-controls.generated';
import {
  FdrWishlistControlsQuery,
  useFdrWishlistControlsLazyQuery,
} from 'fdr/schemas/query/wishlist/search-controls/fdr-wishlist-controls.generated';

interface IGetVariablesArgs {
  id?: string;
  travelType: T_Custom_FdrTravelTypeUnit;
}

const RESET_FILTERS_EVENT = 'resetFilters';

export function dispatchResetFiltersEvent() {
  const event = new CustomEvent(RESET_FILTERS_EVENT);
  document.dispatchEvent(event);
}

export function listenForResetFiltersEvent(callback: () => void) {
  document.addEventListener(RESET_FILTERS_EVENT, callback);
  return () => {
    document.removeEventListener(RESET_FILTERS_EVENT, callback);
  };
}

const defaultValuesMap = {
  [ESearchType.SRL]: {
    lazyQuery: useFdrSearchControlsLazyQuery,
    getVariables: ({ travelType }: IGetVariablesArgs) => ({ travelType }),
    map: (data: FdrSearchControlsQuery) =>
      data?.fdrProductSearchResultListPage?.searchControls.controls.filters,
  },
  [ESearchType.ORL]: {
    lazyQuery: useFdrPdpSearchControlsLazyQuery,
    getVariables: ({ id, travelType }: IGetVariablesArgs) => ({
      id,
      travelType,
    }),
    map: (data: FdrPdpSearchControlsQuery) => {
      const product = data?.fdrProduct;

      switch (product?.__typename) {
        case 'FdrHotel':
        case 'FdrCruise':
        case 'FdrRoundTrip': {
          return product?.searchControls.controls.filters;
        }
      }
    },
  },
  [ESearchType.FLIGHT]: {
    lazyQuery: useFdrFlightSearchControlsLazyQuery,
    getVariables: ({ travelType }: IGetVariablesArgs) => ({ travelType }),
    map: (data: FdrFlightSearchControlsQuery) =>
      data?.fdrFlightSearchResultListPage.flightSearchControls.controls
        ?.filters,
  },
  [ESearchType.GEO]: {
    lazyQuery: useFdrGeoSearchControlsLazyQuery,
    getVariables: ({ id, travelType }: IGetVariablesArgs) => ({
      id,
      travelType,
    }),
    map: (data: FdrGeoSearchControlsQuery) =>
      data?.fdrGeo?.searchControls.controls.filters,
  },
  [ESearchType.THEME]: {
    lazyQuery: useFdrThemeSearchControlsLazyQuery,
    getVariables: ({ id, travelType }: IGetVariablesArgs) => ({
      id,
      travelType,
    }),
    map: (data: FdrThemeSearchControlsQuery) =>
      data?.fdrTheme?.searchControls.controls.filters,
  },
  [ESearchType.WISHLIST]: {
    lazyQuery: useFdrWishlistControlsLazyQuery,
    getVariables: ({ travelType }: IGetVariablesArgs) => ({ travelType }),
    map: (data: FdrWishlistControlsQuery) =>
      data?.fdrWishlistPage?.searchControls.controls.filters,
  },
};

interface IFdrResetFiltersForm {
  resetHandler: (
    setValues?: (defaultValues: IFdrFiltersFormState) => void
  ) => Promise<void>;
  loading: boolean;
}

export function useFdrResetFiltersForm(
  searchType: ESearchType
): IFdrResetFiltersForm {
  const { id } = useObjectId();
  const { mobile } = useDeviceType();
  const { state, setState } = useSearchState<IFdrSRLState>();

  const currentTravelType = state.searchControl.extended_travelType?.fdr;

  const {
    lazyQuery,
    getVariables,
    map: defaultFiltersMapper,
  } = defaultValuesMap[searchType];

  const [query, { loading: defaultValuesLoading }] = lazyQuery();

  return {
    loading: !mobile ? defaultValuesLoading : false, // ignore loading on mobile
    resetHandler: async (
      setValues?: (defaultValues: IFdrFiltersFormState) => void
    ) => {
      dispatchResetFiltersEvent();

      const { data: defaultValuesData } = await query({
        variables: getVariables({ id, travelType: currentTravelType }),
      });

      const defaultFilters = defaultFiltersMapper(defaultValuesData);

      if (!defaultValuesLoading && defaultFilters) {
        const mappedDefaultFilters = fdrMapFilters(defaultFilters);
        const mappedDefaultNormalizedFilters = normalizeFilters(defaultFilters);

        setValues?.(mappedDefaultFilters);

        if (!mobile) {
          setState(prev => ({
            ...prev,
            filters: mappedDefaultFilters,
            normalizedFilters: mappedDefaultNormalizedFilters,
          }));
        }
      }
    },
  };
}
