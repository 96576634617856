import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrLinkFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-link.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrFlightSearchLinkQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.FdrFlightSearchResultListWebMetaInput>;
}>;

export type FdrFlightSearchLinkQuery = {
  __typename?: 'Query';
  fdrFlightSearchResultListPage?: {
    __typename?: 'FdrFlightSearchResultListPage';
    webMeta?: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    } | null;
  } | null;
};

export const FdrFlightSearchLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrFlightSearchLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'FdrFlightSearchResultListWebMetaInput',
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrFlightSearchResultListPage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrLink' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrFlightSearchLinkQuery__
 *
 * To run a query within a React component, call `useFdrFlightSearchLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrFlightSearchLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrFlightSearchLinkQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFdrFlightSearchLinkQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FdrFlightSearchLinkQuery,
    FdrFlightSearchLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrFlightSearchLinkQuery,
    FdrFlightSearchLinkQueryVariables
  >(FdrFlightSearchLinkDocument, options);
}
export function useFdrFlightSearchLinkLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrFlightSearchLinkQuery,
    FdrFlightSearchLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrFlightSearchLinkQuery,
    FdrFlightSearchLinkQueryVariables
  >(FdrFlightSearchLinkDocument, options);
}
export type FdrFlightSearchLinkQueryHookResult = ReturnType<
  typeof useFdrFlightSearchLinkQuery
>;
export type FdrFlightSearchLinkLazyQueryHookResult = ReturnType<
  typeof useFdrFlightSearchLinkLazyQuery
>;
export type FdrFlightSearchLinkQueryResult = Apollo.QueryResult<
  FdrFlightSearchLinkQuery,
  FdrFlightSearchLinkQueryVariables
>;
