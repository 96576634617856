import React from 'react';
import styled from 'styled-components';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import {
  SearchFilterAccordionWrap,
  searchFiltersTagsWrapperCss,
  searchFilterWrapCss,
} from 'components/domain/filters/search-filter-input/SearchFilterInput.styles';
import { default as FilterTagsSkeleton } from 'components/domain/tag/TagBox.skeleton';

const SearchFilterSkeletonWrap = styled.div(searchFilterWrapCss);

const SearchFilterTagsWrap = styled.div(searchFiltersTagsWrapperCss);

export const SearchFilterAccordionSkeleton: React.FC<{
  count?: number;
}> = React.memo(({ count = 5 }) => {
  const WIDTH = 360; // relative width
  const ITEM_HEIGHT = 59; // exact single item height
  const FONT_S = 16; // font size, and size for arrow
  const X_PADDING = 10; // x-axis padding

  return (
    <PlaceholderLoader
      uid="searchFilterAccordionSkeleton"
      width={WIDTH}
      height={ITEM_HEIGHT * count}
      style={{ width: '100%', height: `${ITEM_HEIGHT * count}px` }}
    >
      {Array.from({ length: count }, (_, i) => (
        <React.Fragment key={i}>
          <rect
            y={i * ITEM_HEIGHT + Math.ceil((ITEM_HEIGHT - FONT_S) / 2)}
            x={X_PADDING}
            width={i % 2 === 0 ? 170 : 100}
            height={FONT_S}
            rx={5}
          />
          <rect
            y={i * ITEM_HEIGHT + Math.ceil((ITEM_HEIGHT - FONT_S) / 2)}
            x={WIDTH - X_PADDING - FONT_S}
            width={FONT_S}
            height={FONT_S}
            rx={FONT_S}
          />
          {i > 0 && (
            <rect
              y={i * ITEM_HEIGHT + 1}
              x={X_PADDING}
              width={WIDTH - X_PADDING * 2}
              height={1}
            />
          )}
        </React.Fragment>
      ))}
    </PlaceholderLoader>
  );
});

SearchFilterAccordionSkeleton.displayName = 'SearchFilterAccordionSkeleton';

export const SearchFilterInputSkeleton: React.FC = () => {
  const { mobile } = useDeviceType();

  return (
    <SearchFilterSkeletonWrap data-id="searchFiltersSkeleton">
      <SearchFilterAccordionWrap className="search-filters-wrap">
        <SearchFilterAccordionSkeleton count={10} />
      </SearchFilterAccordionWrap>
      <SearchFilterTagsWrap>
        {!mobile && (
          <PlaceholderLoader
            uid="searchFilterTagsWrapSkeleton"
            width={370}
            height={54}
            style={{ width: '100%', height: '54px', display: 'block' }}
          >
            <rect x={20} y={14} width={150} height={26} rx={8} />
          </PlaceholderLoader>
        )}
        <FilterTagsSkeleton />
      </SearchFilterTagsWrap>
    </SearchFilterSkeletonWrap>
  );
};
