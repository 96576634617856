import format from 'date-fns/format';
import { convertDOBStrToISOStr } from '@hotelplan/components.common.dob-input';
import {
  getReturnDate,
  mapExactTravelPeriodToFormTravelDates,
} from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import type { IRunSearchData } from '@hotelplan/libs.tracking';
import {
  FdrFlightClass,
  FdrFlightSearchControlsTravellers,
  FdrFlightSearchCriteriaInput,
  FdrFlightSorting,
  FdrFlightType,
} from '@hotelplan/supergraph-api';
import { normalizeFilters } from 'fdr/components/candidate/fdr-search-filters-controls/use-fdr-filter-render.utils';
import {
  fdrMapFilters,
  getOptionToFilterMap,
  mapFdrFiltersStateToTrackableData,
} from 'fdr/components/candidate/fdr-search/mappers/fdr-filter.mapper';
import { mapSearchControlFormStateToTrackableData } from 'fdr/components/candidate/fdr-search/mappers/fdr-search-control.mappers';
import {
  collectPriceCriteria,
  filterEmptyCriteria,
} from 'fdr/components/candidate/fdr-search/mappers/fdr-srl-form-to-criteria-input.mapper';
import { getFdrControlsTravelRooms } from 'fdr/components/candidate/fdr-search/mappers/fdr-srl-response-to-state.mapper';
import {
  EFilterIds,
  IFdrFiltersFormState,
} from 'fdr/components/candidate/fdr-search/types/filters.types';
import { EFdrFlightTravelType } from 'fdr/components/candidate/fdr-search/types/travel-type.types';
import { FdrSearchControlsFragment } from 'fdr/schemas/fragment/search-controls/fdr-search-controls.generated';
import {
  FdrFlightSearchControlsLiteFragment,
  FdrFlightSearchControlsQuery,
  FdrSearchControlFlightFragment,
} from 'fdr/schemas/query/flight-search/form/fdr-flight-search-controls.generated';
import {
  FdrFlightPartition,
  IFdrFlightSRLState,
  TFdrFlightSrlControlStateWithType,
} from './fdr-flight-search.types';
import {
  prepareFdrFlightPartitionsToCriteriaInput,
  prepareFdrFlightPartitionsToFormState,
} from './flight-partitions/fdr-flight-partitions.utils';

export const DATE_INPUT_FORMAT = 'yyyy-MM-dd';

export function mapFdrFlightSearchControlsToFlightSRLState(
  values: FdrFlightSearchControlsQuery,
  defaultData: FdrFlightSearchControlsQuery
): IFdrFlightSRLState {
  const mappedState = fdrMapFlightSRLSearchValuesQueryToFlightSRLState(values);

  const defaultState = defaultData
    ? fdrMapFlightSRLSearchValuesQueryToFlightSRLState(defaultData)
    : undefined;

  if (
    defaultState?.searchControl.flightPartitions &&
    mappedState?.searchControl.flightPartitions
  ) {
    defaultState.searchControl.flightPartitions.forEach((partition, index) => {
      const mappedPartition =
        mappedState.searchControl.flightPartitions?.[index];

      if (!mappedPartition?.travelDates || !partition.travelDates) return;

      mappedPartition.travelDates.defaults = {
        exact: partition.travelDates,
        flexible: partition.travelDates,
      };
    });
  }

  return mappedState;
}

export function fdrMapFlightSRLSearchValuesQueryToFlightSRLState(
  values: FdrFlightSearchControlsQuery
): IFdrFlightSRLState {
  const controls =
    values?.fdrFlightSearchResultListPage?.flightSearchControls?.controls;

  const selectedFilters = fdrMapFilters(controls.filters);

  return {
    searchControl:
      fdrMapFlightSRLSearchControlValuesToFlightFormState(controls),
    normalizedFilters: normalizeFilters(controls.filters),
    filters: selectedFilters,
    optionToFilterMap: getOptionToFilterMap(controls.filters),
  };
}

export function fdrMapFlightSRLSearchControlValuesToFlightFormState(
  controls: FdrFlightSearchControlsLiteFragment
): TFdrFlightSrlControlStateWithType {
  switch (controls.__typename) {
    case 'FdrOneWayFlightSearchControls': {
      return {
        type: 'FLIGHT',
        extended_travelType: {
          fdr: EFdrFlightTravelType.Flight,
        },
        flightType: FdrFlightType.OneWay,
        travelRooms: getFdrControlsTravelRooms({
          travellers: mapFdrFlightTravellersToSearchControlTravellers(
            controls.travellers
          ),
          rooms: 0,
        } as FdrSearchControlsFragment), // todo: fix type to prevent using "as" format
        flightPartitions: prepareFdrFlightPartitionsToFormState(
          mapFdrSearchControlFlightsToFlightPartitions(controls.flightClass, [
            controls.flight,
          ]),
          FdrFlightType.OneWay
        ),
      };
    }
    case 'FdrOpenJawFlightSearchControls': {
      return {
        type: 'FLIGHT',
        extended_travelType: {
          fdr: EFdrFlightTravelType.Flight,
        },
        flightType: FdrFlightType.OpenJaw,
        travelRooms: getFdrControlsTravelRooms({
          travellers: mapFdrFlightTravellersToSearchControlTravellers(
            controls.travellers
          ),
          rooms: 0,
        } as FdrSearchControlsFragment), // todo: fix type to prevent using "as" format
        flightPartitions: prepareFdrFlightPartitionsToFormState(
          mapFdrSearchControlFlightsToFlightPartitions(
            controls.flightClass,
            controls.flights
          ),
          FdrFlightType.OpenJaw
        ),
      };
    }
    case 'FdrReturnFlightSearchControls': {
      return {
        type: 'FLIGHT',
        extended_travelType: {
          fdr: EFdrFlightTravelType.Flight,
        },
        flightType: FdrFlightType.Return,
        travelRooms: getFdrControlsTravelRooms({
          travellers: mapFdrFlightTravellersToSearchControlTravellers(
            controls.travellers
          ),
          rooms: 0,
        } as FdrSearchControlsFragment), // todo: fix type to prevent using "as" format
        flightPartitions: prepareFdrFlightPartitionsToFormState(
          mapFdrSearchControlFlightsToFlightPartitions(controls.flightClass, [
            controls.flight,
          ]),
          FdrFlightType.Return
        ),
      };
    }
  }
}

function mapFdrFlightTravellersToSearchControlTravellers(
  travellers: FdrFlightSearchControlsTravellers
): FdrSearchControlsFragment['travellers'] {
  return {
    __typename: 'FdrSearchControlsTravellers',
    adults: travellers?.adults || 2,
    childrenDobs: travellers?.childrenDobs || [],
    roomDistribution: undefined,
  };
}

function mapFdrSearchControlFlightsToFlightPartitions(
  flightClass: FdrFlightClass,
  flights: Array<FdrSearchControlFlightFragment>
): Array<FdrFlightPartition> {
  return flights.map(flight => {
    return {
      flightClass: flightClass,
      travelDates: mapExactTravelPeriodToFormTravelDates(
        flight?.departureDate,
        flight?.returnDate
      ),
      arrivalAirport: flight?.arrivalAirport,
      departureAirport: flight?.departureAirport,
    };
  });
}

export function fdrFlightSrlFormToCriteriaInput(
  formState: TFdrFlightSrlControlStateWithType,
  flightFiltersForm?: IFdrFiltersFormState,
  flightSorting?: FdrFlightSorting
): FdrFlightSearchCriteriaInput {
  if (formState?.type !== 'FLIGHT') return null;

  const preparedFlightPartitions = prepareFdrFlightPartitionsToCriteriaInput(
    formState.flightPartitions,
    formState.flightType
  );

  const filters = getFdrFlightFiltersInput(flightFiltersForm);

  return {
    flightType: formState.flightType,
    flightClass: formState.flightPartitions.find(
      partition => partition.flightClass
    ).flightClass,
    flightCriteria: preparedFlightPartitions.map(partition => {
      const adjustedReturnDate = getReturnDate(
        partition.travelDates?.departureDate,
        partition.travelDates?.returnDate,
        partition.travelDates?.duration
      );

      return {
        departureDate: format(
          partition.travelDates?.departureDate,
          DATE_INPUT_FORMAT
        ),
        returnDate: adjustedReturnDate,
        arrivalAirportIatas: [partition.arrivalAirport?.iata],
        departureAirportIatas: [partition.departureAirport?.iata],
      };
    }),
    travellers: {
      adults: formState.travelRooms.adults || 0,
      childrenDobs: (formState.travelRooms.childrenDobs || []).map(
        convertDOBStrToISOStr
      ),
    },
    sorting: flightSorting || FdrFlightSorting.FlightOfferPrice,
    ...filters,
  };
}

export function mapFdrFlightSRLStateToTrackableData(
  state: IFdrFlightSRLState
): IRunSearchData {
  return {
    searchControl: mapSearchControlFormStateToTrackableData(
      state.searchControl
    ),
    filters: mapFdrFiltersStateToTrackableData(state.filters),
  };
}

export function getFdrFlightFiltersInput(
  filtersForm?: IFdrFiltersFormState
): Pick<
  FdrFlightSearchCriteriaInput,
  | 'price'
  | 'stopOverDuration'
  | 'maxStops'
  | 'airlineIatas'
  | 'departureFlightDepartureTime'
  | 'departureFlightArrivalTime'
  | 'returnFlightDepartureTime'
  | 'returnFlightArrivalTime'
> {
  const criteria = {
    stopOverDuration:
      filtersForm?.[EFilterIds.FLIGHT_STOPOVER_DURATION]?.value.selected,
    maxStops: filtersForm?.[EFilterIds.MAX_STOPS]?.value,
    price: collectPriceCriteria(filtersForm),
    airlineIatas: filtersForm?.[EFilterIds.AIRLINES]?.value,
    departureFlightDepartureTime:
      filtersForm?.[EFilterIds.DEPARTURE_FLIGHT_DEPARTURE_TIME]?.value,
    departureFlightArrivalTime:
      filtersForm?.[EFilterIds.DEPARTURE_FLIGHT_ARRIVAL_TIME]?.value,
    returnFlightArrivalTime:
      filtersForm?.[EFilterIds.RETURN_FLIGHT_ARRIVAL_TIME]?.value,
    returnFlightDepartureTime:
      filtersForm?.[EFilterIds.RETURN_FLIGHT_DEPARTURE_TIME]?.value,
  };

  return filterEmptyCriteria(criteria);
}
