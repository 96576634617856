import noop from 'lodash/noop';
import React, { useContext, useRef, useCallback } from 'react';
import { useToggleState } from '@hotelplan/libs.hooks-react';

interface ICurrentSearchContextValue {
  isSearchControlShowed: boolean;
  showSearchControl: () => void;
  closeSearchControl: () => void;
  ref?: React.RefObject<HTMLDivElement>;
  formRef?: React.RefObject<HTMLFormElement>;
  submitSearchControl?: () => void;
}

const CurrentSearchContext = React.createContext<ICurrentSearchContextValue>({
  isSearchControlShowed: false,
  showSearchControl: noop,
  closeSearchControl: noop,
});

const SUBMIT_EVENT = 'submit';

export const CurrentSearchContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [isSearchControlShowed, showSearchControl, closeSearchControl] =
    useToggleState(false);

  const searchControlEL = useRef<HTMLDivElement>(null);
  const searchFormEL = useRef<HTMLFormElement>(null);

  const submitSearchControl = useCallback(() => {
    if (searchFormEL && searchFormEL.current) {
      searchFormEL.current.dispatchEvent(
        new Event(SUBMIT_EVENT, { cancelable: true })
      );
    }
  }, [searchFormEL]);

  return React.createElement(
    CurrentSearchContext.Provider,
    {
      value: {
        isSearchControlShowed,
        showSearchControl,
        closeSearchControl,
        ref: searchControlEL,
        formRef: searchFormEL,
        submitSearchControl,
      },
    },
    children
  );
};

export function useCurrentSearchContext(): ICurrentSearchContextValue {
  return useContext(CurrentSearchContext);
}

export default CurrentSearchContext;
