import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrFlightFiltersFragmentDoc } from 'fdr/schemas/fragment/filters/fdr-filters.generated';
import { FdrAirportFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-airport.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrFlightSearchControlsQueryVariables = Types.Exact<{
  searchQuery?: Types.InputMaybe<Types.Scalars['String']>;
}>;

export type FdrFlightSearchControlsQuery = {
  __typename?: 'Query';
  fdrFlightSearchResultListPage?: {
    __typename?: 'FdrFlightSearchResultListPage';
    flightSearchControls: {
      __typename?: 'FdrFlightSearchControlsResponse';
      controls:
        | {
            __typename?: 'FdrOneWayFlightSearchControls';
            sorting: Types.FdrFlightSorting;
            flightClass: Types.FdrFlightClass;
            sortingDirection?: Types.FdrSortDirection | null;
            filters?: Array<
              | {
                  __typename: 'FdrFlightSearchControlsMultiSelectFilter';
                  visible: boolean;
                  id: string;
                  name?: string | null;
                  options: Array<
                    | {
                        __typename: 'FdrSearchControlsAirlineOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        airline?: {
                          __typename?: 'FdrAirline';
                          name: string;
                          iata: string;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureFlightArrivalTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsFlightStopoverDurationOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        availableRange?: {
                          __typename?: 'FdrFlightStopoverDurationRange';
                          minDuration?: number | null;
                          maxDuration?: number | null;
                        } | null;
                        selectedRange?: {
                          __typename?: 'FdrFlightStopoverDurationRange';
                          minDuration?: number | null;
                          maxDuration?: number | null;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxPriceOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        maxPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxStopsOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        value?: number | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMinPriceOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        minPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsReturnFlightArrivalTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        to?: any | null;
                        from?: any | null;
                        caption?: string | null;
                      }
                  >;
                }
              | {
                  __typename: 'FdrFlightSearchControlsOneOptionFilter';
                  visible: boolean;
                  id: string;
                  name?: string | null;
                  option:
                    | {
                        __typename: 'FdrSearchControlsAirlineOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        airline?: {
                          __typename?: 'FdrAirline';
                          name: string;
                          iata: string;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureFlightArrivalTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsFlightStopoverDurationOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        availableRange?: {
                          __typename?: 'FdrFlightStopoverDurationRange';
                          minDuration?: number | null;
                          maxDuration?: number | null;
                        } | null;
                        selectedRange?: {
                          __typename?: 'FdrFlightStopoverDurationRange';
                          minDuration?: number | null;
                          maxDuration?: number | null;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxPriceOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        maxPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxStopsOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        value?: number | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMinPriceOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        minPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsReturnFlightArrivalTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        to?: any | null;
                        from?: any | null;
                        caption?: string | null;
                      };
                }
              | {
                  __typename: 'FdrFlightSearchControlsSingleSelectFilter';
                  visible: boolean;
                  id: string;
                  name?: string | null;
                  options: Array<
                    | {
                        __typename: 'FdrSearchControlsAirlineOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        airline?: {
                          __typename?: 'FdrAirline';
                          name: string;
                          iata: string;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureFlightArrivalTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsFlightStopoverDurationOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        availableRange?: {
                          __typename?: 'FdrFlightStopoverDurationRange';
                          minDuration?: number | null;
                          maxDuration?: number | null;
                        } | null;
                        selectedRange?: {
                          __typename?: 'FdrFlightStopoverDurationRange';
                          minDuration?: number | null;
                          maxDuration?: number | null;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxPriceOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        maxPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxStopsOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        value?: number | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMinPriceOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        minPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsReturnFlightArrivalTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        to?: any | null;
                        from?: any | null;
                        caption?: string | null;
                      }
                  >;
                }
            > | null;
            flight: {
              __typename?: 'FdrSearchControlFlight';
              departureDate: any;
              returnDate: any;
              arrivalAirport?: {
                __typename?: 'FdrAirport';
                name: string;
                isGroup: boolean;
                iata: string;
                codes?: Array<string> | null;
                coordinates?: {
                  __typename?: 'FdrCoordinates';
                  longitude: number;
                  latitude: number;
                } | null;
              } | null;
              departureAirport?: {
                __typename?: 'FdrAirport';
                name: string;
                isGroup: boolean;
                iata: string;
                codes?: Array<string> | null;
                coordinates?: {
                  __typename?: 'FdrCoordinates';
                  longitude: number;
                  latitude: number;
                } | null;
              } | null;
            };
            travellers: {
              __typename?: 'FdrFlightSearchControlsTravellers';
              adults: number;
              childrenDobs?: Array<any> | null;
            };
          }
        | {
            __typename?: 'FdrOpenJawFlightSearchControls';
            sorting: Types.FdrFlightSorting;
            flightClass: Types.FdrFlightClass;
            sortingDirection?: Types.FdrSortDirection | null;
            filters?: Array<
              | {
                  __typename: 'FdrFlightSearchControlsMultiSelectFilter';
                  visible: boolean;
                  id: string;
                  name?: string | null;
                  options: Array<
                    | {
                        __typename: 'FdrSearchControlsAirlineOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        airline?: {
                          __typename?: 'FdrAirline';
                          name: string;
                          iata: string;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureFlightArrivalTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsFlightStopoverDurationOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        availableRange?: {
                          __typename?: 'FdrFlightStopoverDurationRange';
                          minDuration?: number | null;
                          maxDuration?: number | null;
                        } | null;
                        selectedRange?: {
                          __typename?: 'FdrFlightStopoverDurationRange';
                          minDuration?: number | null;
                          maxDuration?: number | null;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxPriceOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        maxPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxStopsOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        value?: number | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMinPriceOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        minPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsReturnFlightArrivalTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        to?: any | null;
                        from?: any | null;
                        caption?: string | null;
                      }
                  >;
                }
              | {
                  __typename: 'FdrFlightSearchControlsOneOptionFilter';
                  visible: boolean;
                  id: string;
                  name?: string | null;
                  option:
                    | {
                        __typename: 'FdrSearchControlsAirlineOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        airline?: {
                          __typename?: 'FdrAirline';
                          name: string;
                          iata: string;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureFlightArrivalTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsFlightStopoverDurationOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        availableRange?: {
                          __typename?: 'FdrFlightStopoverDurationRange';
                          minDuration?: number | null;
                          maxDuration?: number | null;
                        } | null;
                        selectedRange?: {
                          __typename?: 'FdrFlightStopoverDurationRange';
                          minDuration?: number | null;
                          maxDuration?: number | null;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxPriceOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        maxPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxStopsOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        value?: number | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMinPriceOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        minPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsReturnFlightArrivalTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        to?: any | null;
                        from?: any | null;
                        caption?: string | null;
                      };
                }
              | {
                  __typename: 'FdrFlightSearchControlsSingleSelectFilter';
                  visible: boolean;
                  id: string;
                  name?: string | null;
                  options: Array<
                    | {
                        __typename: 'FdrSearchControlsAirlineOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        airline?: {
                          __typename?: 'FdrAirline';
                          name: string;
                          iata: string;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureFlightArrivalTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsFlightStopoverDurationOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        availableRange?: {
                          __typename?: 'FdrFlightStopoverDurationRange';
                          minDuration?: number | null;
                          maxDuration?: number | null;
                        } | null;
                        selectedRange?: {
                          __typename?: 'FdrFlightStopoverDurationRange';
                          minDuration?: number | null;
                          maxDuration?: number | null;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxPriceOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        maxPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxStopsOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        value?: number | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMinPriceOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        minPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsReturnFlightArrivalTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        to?: any | null;
                        from?: any | null;
                        caption?: string | null;
                      }
                  >;
                }
            > | null;
            flights: Array<{
              __typename?: 'FdrSearchControlFlight';
              departureDate: any;
              returnDate: any;
              arrivalAirport?: {
                __typename?: 'FdrAirport';
                name: string;
                isGroup: boolean;
                iata: string;
                codes?: Array<string> | null;
                coordinates?: {
                  __typename?: 'FdrCoordinates';
                  longitude: number;
                  latitude: number;
                } | null;
              } | null;
              departureAirport?: {
                __typename?: 'FdrAirport';
                name: string;
                isGroup: boolean;
                iata: string;
                codes?: Array<string> | null;
                coordinates?: {
                  __typename?: 'FdrCoordinates';
                  longitude: number;
                  latitude: number;
                } | null;
              } | null;
            }>;
            travellers: {
              __typename?: 'FdrFlightSearchControlsTravellers';
              adults: number;
              childrenDobs?: Array<any> | null;
            };
          }
        | {
            __typename?: 'FdrReturnFlightSearchControls';
            sorting: Types.FdrFlightSorting;
            flightClass: Types.FdrFlightClass;
            sortingDirection?: Types.FdrSortDirection | null;
            filters?: Array<
              | {
                  __typename: 'FdrFlightSearchControlsMultiSelectFilter';
                  visible: boolean;
                  id: string;
                  name?: string | null;
                  options: Array<
                    | {
                        __typename: 'FdrSearchControlsAirlineOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        airline?: {
                          __typename?: 'FdrAirline';
                          name: string;
                          iata: string;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureFlightArrivalTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsFlightStopoverDurationOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        availableRange?: {
                          __typename?: 'FdrFlightStopoverDurationRange';
                          minDuration?: number | null;
                          maxDuration?: number | null;
                        } | null;
                        selectedRange?: {
                          __typename?: 'FdrFlightStopoverDurationRange';
                          minDuration?: number | null;
                          maxDuration?: number | null;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxPriceOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        maxPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxStopsOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        value?: number | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMinPriceOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        minPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsReturnFlightArrivalTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        to?: any | null;
                        from?: any | null;
                        caption?: string | null;
                      }
                  >;
                }
              | {
                  __typename: 'FdrFlightSearchControlsOneOptionFilter';
                  visible: boolean;
                  id: string;
                  name?: string | null;
                  option:
                    | {
                        __typename: 'FdrSearchControlsAirlineOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        airline?: {
                          __typename?: 'FdrAirline';
                          name: string;
                          iata: string;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureFlightArrivalTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsFlightStopoverDurationOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        availableRange?: {
                          __typename?: 'FdrFlightStopoverDurationRange';
                          minDuration?: number | null;
                          maxDuration?: number | null;
                        } | null;
                        selectedRange?: {
                          __typename?: 'FdrFlightStopoverDurationRange';
                          minDuration?: number | null;
                          maxDuration?: number | null;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxPriceOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        maxPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxStopsOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        value?: number | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMinPriceOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        minPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsReturnFlightArrivalTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        to?: any | null;
                        from?: any | null;
                        caption?: string | null;
                      };
                }
              | {
                  __typename: 'FdrFlightSearchControlsSingleSelectFilter';
                  visible: boolean;
                  id: string;
                  name?: string | null;
                  options: Array<
                    | {
                        __typename: 'FdrSearchControlsAirlineOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        airline?: {
                          __typename?: 'FdrAirline';
                          name: string;
                          iata: string;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureFlightArrivalTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsDepartureFlightDepartureTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsFlightStopoverDurationOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        availableRange?: {
                          __typename?: 'FdrFlightStopoverDurationRange';
                          minDuration?: number | null;
                          maxDuration?: number | null;
                        } | null;
                        selectedRange?: {
                          __typename?: 'FdrFlightStopoverDurationRange';
                          minDuration?: number | null;
                          maxDuration?: number | null;
                        } | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxPriceOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        maxPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMaxStopsOption';
                        visible: boolean;
                        id: string;
                        selected: boolean;
                        caption?: string | null;
                        value?: number | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsMinPriceOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        minPrice?: number | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsReturnFlightArrivalTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        from?: any | null;
                        to?: any | null;
                        caption?: string | null;
                      }
                    | {
                        __typename: 'FdrSearchControlsReturnFlightDepartureTimeOption';
                        selected: boolean;
                        id: string;
                        visible: boolean;
                        to?: any | null;
                        from?: any | null;
                        caption?: string | null;
                      }
                  >;
                }
            > | null;
            flight: {
              __typename?: 'FdrSearchControlFlight';
              departureDate: any;
              returnDate: any;
              arrivalAirport?: {
                __typename?: 'FdrAirport';
                name: string;
                isGroup: boolean;
                iata: string;
                codes?: Array<string> | null;
                coordinates?: {
                  __typename?: 'FdrCoordinates';
                  longitude: number;
                  latitude: number;
                } | null;
              } | null;
              departureAirport?: {
                __typename?: 'FdrAirport';
                name: string;
                isGroup: boolean;
                iata: string;
                codes?: Array<string> | null;
                coordinates?: {
                  __typename?: 'FdrCoordinates';
                  longitude: number;
                  latitude: number;
                } | null;
              } | null;
            };
            travellers: {
              __typename?: 'FdrFlightSearchControlsTravellers';
              adults: number;
              childrenDobs?: Array<any> | null;
            };
          };
    };
  } | null;
};

export type FdrFlightSearchControlsLiteFdrOneWayFlightSearchControlsFragment = {
  __typename?: 'FdrOneWayFlightSearchControls';
  sorting: Types.FdrFlightSorting;
  flightClass: Types.FdrFlightClass;
  sortingDirection?: Types.FdrSortDirection | null;
  flight: {
    __typename?: 'FdrSearchControlFlight';
    departureDate: any;
    returnDate: any;
    arrivalAirport?: {
      __typename?: 'FdrAirport';
      name: string;
      isGroup: boolean;
      iata: string;
      codes?: Array<string> | null;
      coordinates?: {
        __typename?: 'FdrCoordinates';
        longitude: number;
        latitude: number;
      } | null;
    } | null;
    departureAirport?: {
      __typename?: 'FdrAirport';
      name: string;
      isGroup: boolean;
      iata: string;
      codes?: Array<string> | null;
      coordinates?: {
        __typename?: 'FdrCoordinates';
        longitude: number;
        latitude: number;
      } | null;
    } | null;
  };
  travellers: {
    __typename?: 'FdrFlightSearchControlsTravellers';
    adults: number;
    childrenDobs?: Array<any> | null;
  };
};

export type FdrFlightSearchControlsLiteFdrOpenJawFlightSearchControlsFragment =
  {
    __typename?: 'FdrOpenJawFlightSearchControls';
    sorting: Types.FdrFlightSorting;
    flightClass: Types.FdrFlightClass;
    sortingDirection?: Types.FdrSortDirection | null;
    flights: Array<{
      __typename?: 'FdrSearchControlFlight';
      departureDate: any;
      returnDate: any;
      arrivalAirport?: {
        __typename?: 'FdrAirport';
        name: string;
        isGroup: boolean;
        iata: string;
        codes?: Array<string> | null;
        coordinates?: {
          __typename?: 'FdrCoordinates';
          longitude: number;
          latitude: number;
        } | null;
      } | null;
      departureAirport?: {
        __typename?: 'FdrAirport';
        name: string;
        isGroup: boolean;
        iata: string;
        codes?: Array<string> | null;
        coordinates?: {
          __typename?: 'FdrCoordinates';
          longitude: number;
          latitude: number;
        } | null;
      } | null;
    }>;
    travellers: {
      __typename?: 'FdrFlightSearchControlsTravellers';
      adults: number;
      childrenDobs?: Array<any> | null;
    };
  };

export type FdrFlightSearchControlsLiteFdrReturnFlightSearchControlsFragment = {
  __typename?: 'FdrReturnFlightSearchControls';
  sorting: Types.FdrFlightSorting;
  flightClass: Types.FdrFlightClass;
  sortingDirection?: Types.FdrSortDirection | null;
  flight: {
    __typename?: 'FdrSearchControlFlight';
    departureDate: any;
    returnDate: any;
    arrivalAirport?: {
      __typename?: 'FdrAirport';
      name: string;
      isGroup: boolean;
      iata: string;
      codes?: Array<string> | null;
      coordinates?: {
        __typename?: 'FdrCoordinates';
        longitude: number;
        latitude: number;
      } | null;
    } | null;
    departureAirport?: {
      __typename?: 'FdrAirport';
      name: string;
      isGroup: boolean;
      iata: string;
      codes?: Array<string> | null;
      coordinates?: {
        __typename?: 'FdrCoordinates';
        longitude: number;
        latitude: number;
      } | null;
    } | null;
  };
  travellers: {
    __typename?: 'FdrFlightSearchControlsTravellers';
    adults: number;
    childrenDobs?: Array<any> | null;
  };
};

export type FdrFlightSearchControlsLiteFragment =
  | FdrFlightSearchControlsLiteFdrOneWayFlightSearchControlsFragment
  | FdrFlightSearchControlsLiteFdrOpenJawFlightSearchControlsFragment
  | FdrFlightSearchControlsLiteFdrReturnFlightSearchControlsFragment;

export type FdrFlightSearchControlTravellersFragment = {
  __typename?: 'FdrFlightSearchControlsTravellers';
  adults: number;
  childrenDobs?: Array<any> | null;
};

export type FdrSearchControlFlightFragment = {
  __typename?: 'FdrSearchControlFlight';
  departureDate: any;
  returnDate: any;
  arrivalAirport?: {
    __typename?: 'FdrAirport';
    name: string;
    isGroup: boolean;
    iata: string;
    codes?: Array<string> | null;
    coordinates?: {
      __typename?: 'FdrCoordinates';
      longitude: number;
      latitude: number;
    } | null;
  } | null;
  departureAirport?: {
    __typename?: 'FdrAirport';
    name: string;
    isGroup: boolean;
    iata: string;
    codes?: Array<string> | null;
    coordinates?: {
      __typename?: 'FdrCoordinates';
      longitude: number;
      latitude: number;
    } | null;
  } | null;
};

export const FdrFlightSearchControlTravellersFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrFlightSearchControlTravellers' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrFlightSearchControlsTravellers' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'adults' } },
          { kind: 'Field', name: { kind: 'Name', value: 'childrenDobs' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const FdrSearchControlFlightFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrSearchControlFlight' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrSearchControlFlight' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'departureDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'arrivalAirport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrAirport' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'departureAirport' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrAirport' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrAirportFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrFlightSearchControlsLiteFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrFlightSearchControlsLite' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrFlightSearchControls' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'sorting' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flightClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortingDirection' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'travellers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'fdrFlightSearchControlTravellers',
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrOneWayFlightSearchControls' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'flight' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrSearchControlFlight' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrOpenJawFlightSearchControls' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'flights' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrSearchControlFlight' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrReturnFlightSearchControls' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'flight' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrSearchControlFlight' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrFlightSearchControlTravellersFragmentDoc.definitions,
    ...FdrSearchControlFlightFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrFlightSearchControlsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrFlightSearchControls' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchQuery' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrFlightSearchResultListPage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'flightSearchControls' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'searchQuery' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'searchQuery' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'controls' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrFlightSearchControlsLite',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'filters' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'fdrFlightFilters',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrFlightSearchControlsLiteFragmentDoc.definitions,
    ...FdrFlightFiltersFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrFlightSearchControlsQuery__
 *
 * To run a query within a React component, call `useFdrFlightSearchControlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrFlightSearchControlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrFlightSearchControlsQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useFdrFlightSearchControlsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FdrFlightSearchControlsQuery,
    FdrFlightSearchControlsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrFlightSearchControlsQuery,
    FdrFlightSearchControlsQueryVariables
  >(FdrFlightSearchControlsDocument, options);
}
export function useFdrFlightSearchControlsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrFlightSearchControlsQuery,
    FdrFlightSearchControlsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrFlightSearchControlsQuery,
    FdrFlightSearchControlsQueryVariables
  >(FdrFlightSearchControlsDocument, options);
}
export type FdrFlightSearchControlsQueryHookResult = ReturnType<
  typeof useFdrFlightSearchControlsQuery
>;
export type FdrFlightSearchControlsLazyQueryHookResult = ReturnType<
  typeof useFdrFlightSearchControlsLazyQuery
>;
export type FdrFlightSearchControlsQueryResult = Apollo.QueryResult<
  FdrFlightSearchControlsQuery,
  FdrFlightSearchControlsQueryVariables
>;
