import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrLinkFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-link.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrSearchLinkQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.FdrProductSearchResultListWebMetaInput>;
}>;

export type FdrSearchLinkQuery = {
  __typename?: 'Query';
  fdrProductSearchResultListPage?: {
    __typename?: 'FdrProductSearchResultListPage';
    webMeta: {
      __typename?: 'FdrMeta';
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    };
  } | null;
};

export const FdrSearchLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrSearchLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'FdrProductSearchResultListWebMetaInput',
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrProductSearchResultListPage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'webMeta' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'link' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrLink' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrSearchLinkQuery__
 *
 * To run a query within a React component, call `useFdrSearchLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrSearchLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrSearchLinkQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFdrSearchLinkQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FdrSearchLinkQuery,
    FdrSearchLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrSearchLinkQuery,
    FdrSearchLinkQueryVariables
  >(FdrSearchLinkDocument, options);
}
export function useFdrSearchLinkLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrSearchLinkQuery,
    FdrSearchLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrSearchLinkQuery,
    FdrSearchLinkQueryVariables
  >(FdrSearchLinkDocument, options);
}
export type FdrSearchLinkQueryHookResult = ReturnType<
  typeof useFdrSearchLinkQuery
>;
export type FdrSearchLinkLazyQueryHookResult = ReturnType<
  typeof useFdrSearchLinkLazyQuery
>;
export type FdrSearchLinkQueryResult = Apollo.QueryResult<
  FdrSearchLinkQuery,
  FdrSearchLinkQueryVariables
>;
