import type { CSSObject } from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import styled from 'styled-components';
import { BsButton } from '@hotelplan/core.basis.bs-button';

export const searchFilterWrapCss: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
};

export const searchFiltersTagsWrapperCss = sx2CssThemeFn({
  boxShadow: ['topMedium', 'none'],
  position: ['sticky', 'static'],
  zIndex: ['1', 'auto'],
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'background',
  order: [null, '-1'],
  borderRadius: [null, '4px 4px 0 0'],
  overflow: [null, 'hidden'],
  flexShrink: '0',
  '.filter-tags': {
    borderTop: 'none',
    '.tag-box-chevron': {
      right: 3,
    },
  },
});

export const SearchFilterWrap = styled.div(searchFilterWrapCss);

export const SearchFilterAccordionWrap = styled.div<{
  haveSelectedFilters?: boolean;
}>(({ haveSelectedFilters }) =>
  sx2CssThemeFn({
    pb: [4, 0],
    flex: ['1', '0 0 auto'],
    backgroundColor: [null, 'background'],
    borderRadius: [null, '0 0 4px 4px'],
    boxShadow: [null, '0 2px 4px 0 rgba(0,0,0,0.06)'],
    '.accordionButtonWrap': {
      px: [null, 3],
    },
    '.accordionItem': {
      '&:first-child': {
        '.accordionButton': {
          borderTopColor: [
            'transparent',
            haveSelectedFilters ? 'lightGreyFirst' : 'transparent',
          ],
        },
      },
      '&:last-child': {
        '.accordionButton': {
          marginBottom: [null, '-1px'],
        },
      },
    },
  })
);

export const SearchFilterTagsWrap = styled.div(searchFiltersTagsWrapperCss);

export const FiltersHeaderWrap = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pt: 3,
    mx: 3,
    borderBottom: '1px solid',
    borderColor: 'lightGreyFirst',
  })
);

export const ResetFiltersButton = styled(BsButton)(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.LINK_LARGE,
    letterSpacing: '0.7px',
    py: 0,
    pb: 3,
  })
);

export const ResetFiltersButtonMobile = styled(BsButton)({
  padding: '16px 0 0 16px',
});

export const FiltersCheckboxControl = styled.span<{ disabled: boolean }>(
  ({ theme, theme: { colors }, disabled }) => ({
    ...{ ...theme.forms.customCheckboxControl },
    borderColor: disabled ? colors.lightGreyFirst : colors.darkGray,
    backgroundColor: disabled
      ? `${colors.lightGreySixth}!important`
      : colors.white,
  })
);
